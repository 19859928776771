const windowHost = window.location.origin;

export const LOCAL_KEY = 'MBAS_LANG';

let pattern = /^(http:\/\/localhost|http:\/\/192\.168|https:\/\/localhost|https:\/\/192\.168|file:\/\/*)/i;
// 判断环境
// export const isMain = pattern.test(windowHost) ? false : true; //上线需打开
export const isMain = false; //本地测试
// 请求api
// export let baseUrl: string = 'http://192.168.1.37:28888';
export let baseUrl: string = 'https://test.solanax.biz/api';
// 合约相关配置
export const LOCAL_RPC = 'https://api.devnet.solana.com/'; // rpc

//
export const LOCAL_programId = 'JQYoTGpQtQti9wXnypSqYQPf5ypcJnSC4TTe3wC6KD3'; // programId
export const usdt_receiver1 = 'AR9rMwxsnpQrkGPVQqqmsgaHPkzKPRr9bqkJ34uwExTD'; // to address(85%)
export const usdt_receiver2 = 'H8F5t2oT2ixG91EnuTXJnsfeKDXkJXiz1bAwqR2xWrxU'; // to address(15%)

// 下面参数还没用到
export const usdt_mint = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'; // usdt
export const usdt_receiver = 'H8F5t2oT2ixG91EnuTXJnsfeKDXkJXiz1bAwqR2xWrxU'; // to address
