import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter as Routers } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import PageLoding from '../components/PageLoding';

const Home = React.lazy(() => import('../view/index'));
const My = React.lazy(() => import('../view/my/index'));
const Ido = React.lazy(() => import('../view/ido/index'));
const Task = React.lazy(() => import('../view/task/index'));
const Account = React.lazy(() => import('../view/account/index'));
const AccountFriends = React.lazy(() => import('../view/account/friends'));
const AccountSolAward = React.lazy(() => import('../view/account/SolAward'));
const AccountIntegralAward = React.lazy(() => import('../view/account/integralAward'));
const AccountMapping = React.lazy(() => import('../view/account/mapping'));
const AccountSubscription = React.lazy(() => import('../view/account/subscription'));
const AccountDirectList = React.lazy(() => import('../view/account/directList'));
const Mapping = React.lazy(() => import('../view/mapping/index'));

export default function Router() {
    return (
        <Suspense fallback={<PageLoding></PageLoding>}>
            <Routes>
                <Route path="/*" element={<MainLayout />}>
                    {/* 首页 */}
                    <Route path="" element={<Home />} />
                    <Route path="ido" element={<Ido />} />
                    <Route path="my" element={<My />} />
                    <Route path="task" element={<Task />} />
                    <Route path="mapping" element={<Mapping />} />
                    <Route path="account" element={<Account />} />
                    <Route path="account/friends" element={<AccountFriends />} />
                    <Route path="account/SolAward" element={<AccountSolAward />} />
                    <Route path="account/integralAward" element={<AccountIntegralAward />} />
                    <Route path="account/mapping" element={<AccountMapping />} />
                    <Route path="account/subscription" element={<AccountSubscription />} />
                    <Route path="account/directList" element={<AccountDirectList />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
